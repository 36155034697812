<template>
  <v-container>
    <v-snackbar v-model="snackbar">
      {{ snackbartext }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false"> Okay </v-btn>
      </template>
    </v-snackbar>

    <v-data-table
      :headers="headers"
      item-key="uid"
      :show-expand="expandflag"
      :items="reviewers"
      :search="search"
      :loading="notloaded"
      loading-text="Loading..."
      sort-by="name"
      class="elevation-1"
      :footer-props="{ prevIcon: 'mdi-arrow-left', nextIcon: 'mdi-arrow-right' }"
      @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
      :items-per-page="roles.itemsPerPage"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Reviewers</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          <v-dialog v-model="dialog" max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2 mx-4" v-bind="attrs" v-on="on">Add Reviewers</v-btn>
              <!--<v-btn  icon  v-bind="attrs" v-on="on" :to="backmenu"><v-icon class="blue">arrow_back</v-icon></v-btn>-->
            </template>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <div v-if="alertflag">
                      <v-alert text color="info">
                        <v-row align="center" no-gutters>
                          <v-col cols="12" xs="10" sm="10" md="10" lg="10">{{ alertmsg }}</v-col>
                          <v-spacer></v-spacer>
                          <v-col>
                            <v-btn
                              cols="12"
                              xs="1"
                              sm="1"
                              md="1"
                              lg="1"
                              color="info"
                              outlined
                              @click="alertflag = false"
                            >
                              Okay
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-alert>
                    </div>
                    <v-row>
                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.name"
                          :rules="inputrequired"
                          label="Reviewer Name"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="editedItem.email"
                          :rules="inputrequired"
                          label="Reviewer Email"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" :disabled="!valid" text @click="saveitem">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="12" xs="12" sm="12" md="3" lg="3">
              <h4>ID</h4>
              {{ item.id }}
            </v-col>
          </v-row>
        </td>
      </template>

      <template v-if="editflag" v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click.stop="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click.stop="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div><h2 class="blue">No data Found- Please add Reviewer</h2></div>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { reviewersRef } from "@/fb"
import { formMixin } from "@/mixins/formMixin.js"
//import { collectionsOperation } from "@/mixins/collectionsOperations.js"


export default {
  name: "Reviewers",
  mixins: [formMixin],

  data: () => ({
    dialog: false,
    menuls: false,
    menuns: false,
    search: "",
    domain: "",
    notloaded: true,
    lazy: false,
    valid: true,
    expandflag: true,
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    inputrequired: [(v) => !!v || "Input is required"],

    reviewers: [],
    editedIndex: -1,

    editedItem: {
      name: null,
      email: null,
      id: null,
    }, // wi

    defaultItem: {
      name: null,
      email: null,
      id: null,
    }, // wi
    editflag: false,
    backmenu: "admin",

    snackbar: false,
    alertflag: false,
    alertmsg: "",
    snackbartext: "",
    roles: "",
  }), // end of data

   /*mounted() {
    reviewersRef.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.doc.data().domain == this.domain) {
          if(!this.notloaded)
          this.updaterealtimedata(this.reviewers, change)
        } //end of this.domain
      }) // end of for each
    }) // end of snapshot
  }, */// end of mounted

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Add Reviewer" : "Edit Reviewer"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
  },

  async created() {
    this.roles = await this.$store.state.userProfile

    console.log("Reviewer crated", this.roles)
    if (this.roles) {
      if (this.roles.role.superadmin || this.roles.role.readuser) {
        if (this.roles.role.sysadmin) {
          this.editflag = true
        }
        this.domain = this.roles.domain
        this.initialize()
      } else {
        alert("Not authorized to access this page , kindly contact support")

        this.$router.replace({ name: this.roles.menufrom })
      }
    } else {
      alert("Not authorized to access this page , kindly contact support")
      this.$router.replace({ name: "/login" })
    }
  },

  methods: {
    async initialize() {
      ;(this.reviewers = []),
        // alert("inside the list item");
        await reviewersRef
          .doc(this.domain)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.reviewers = doc.data().reviewers
            }
          })

      this.notloaded = false
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    editItem(item) {
      this.editedIndex = this.reviewers.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    async deleteItem(item) {
      const index = this.reviewers.indexOf(item)
      const deletemsg = "Are you sure you want to delete this Reviewer?" + item.name
      const option = await this.deleteFromFb(deletemsg)
      console.log("inside deleteItem", option, index, item.uid)
      if (option) {
        try {
          this.reviewers.splice(index, 1)
          await reviewersRef.doc(this.domain).set({
            reviewers: this.reviewers,
          })
        } catch (error) {
          console.log("Error while delete :", error)
        }
      }
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    async saveitem() {
      if (this.editedIndex > -1) {
        Object.assign(this.reviewers[this.editedIndex], this.editedItem)
      } // end of  if (this.editedIndex > -1)
      else {
        this.editedItem.id = this.domain + "-" + new Date().getTime()
        this.reviewers.push({ ...this.editedItem })
      }

      console.log("inside saveite update", this.editedItem)
      try {
        await reviewersRef.doc(this.domain).set({
          reviewers: this.reviewers,
        })
        // alert("updated successfully")

        this.snackbar = true
        ;(this.snackbartext = "reviewers details updated successfully"), this.close()
      } catch (error) {
        this.alertflag = true
        this.alertmsg = "Error while updating" + error
      }

      // this.initialize();
    },
  },
}
</script>
